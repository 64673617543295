import React from 'react'
import classNames from 'classnames'

import Link from 'next/link'

import { ExploreEditor, NewArticleBlank, ExternalLink } from '@tellimer/ui/Icon'

import { OnboardingStepTypes, StepperType } from 'types/onboarding.types'

type Props = {
  step: OnboardingStepTypes
  skip: CallableFunction
  variant: StepperType
}

export const PublishPost = ({ variant }: Props) => {
  const classes = {
    btnSingular: classNames({
      'h-9.5 w-full': variant === StepperType.SECONDARY,
    }),
    posts: classNames({
      'flex gap-4': true,
      'flex-col items-stretch md:items-center md:flex-row md:justify-between':
        variant === StepperType.PRIMARY,
      'flex-col': variant === StepperType.SECONDARY,
    }),
    postIcon: classNames('w-12 text-gray-400'),
    postTitle: classNames({
      'mt-2 text-gray-900 font-medium': true,
      'text-base': variant === StepperType.PRIMARY,
      'text-sm': variant === StepperType.SECONDARY,
    }),
    postDescription: classNames({
      'text-sm text-gray-500 font-medium text-center': true,
      'mt-2': variant === StepperType.PRIMARY,
      'mt-1': variant === StepperType.SECONDARY,
    }),
  }

  return (
    <div>
      <div className={classes.posts}>
        <Link href="/posts/new?template=explore-the-editor" passHref>
          <a
            className="flex-1 p-8 border border-gray-300 rounded-lg flex flex-col items-center"
            data-testid="publish-step-view-draft-link"
          >
            <ExploreEditor className={classes.postIcon} />
            <p className={classes.postTitle}>Explore the editor</p>
            <p className={classes.postDescription}>
              View a draft post we’ve already written for you
            </p>
          </a>
        </Link>
        <Link href="/posts/new">
          <a
            className="flex-1 p-8 border border-dashed border-gray-300 rounded-lg flex flex-col items-center"
            data-testid="publish-step-create-post-link"
          >
            <NewArticleBlank className={classes.postIcon} />
            <p className={classes.postTitle}>Create a new post</p>
            <p className={classes.postDescription}>Create a new post from scratch</p>
          </a>
        </Link>
      </div>
      <div className="mt-6">
        <a
          className="inline-flex items-center justify-center px-3 py-2 border border-transparent text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 text-accent-600 bg-white border-accent-600 focus:ring-accent-500 hover:bg-accent-50 w-full md:w-auto"
          href="https://tellimer.notion.site/Scriber-Resources-901d064df24942a5a99aafcae5246525"
          target="_blank"
          rel="noopener noreferrer"
          data-testid="publish-step-visit-resources-link"
        >
          Visit our resources to learn more
          <ExternalLink className="ml-2 text-accent-600" />
        </a>
      </div>
    </div>
  )
}

PublishPost.displayName = 'PublishPost'
