import React from 'react'
import classNames from 'classnames'

import { skipStep } from 'redux/onboardingProgress/skipStep'
import { StepperType, OnboardingStepTypes } from 'types/onboarding.types'
import { NotificationLayout, NotificationManager } from '@tellimer/ui'
import { useAppDispatch } from 'hooks/reduxHooks'

export type OnboardingStepContentProps = {
  step: OnboardingStepTypes
  active?: boolean
  isSkippable: boolean
  Content: any
  openNextItem?: CallableFunction
  position?: number
  variant?: StepperType
}

export const OnboardingStepContent = ({
  step,
  position = 0,
  isSkippable,
  active = true,
  openNextItem,
  Content,
  variant,
}: OnboardingStepContentProps) => {
  const dispatch = useAppDispatch()

  const skip = async () => {
    try {
      await dispatch(skipStep(step)).unwrap()
    } catch (err) {
      console.error(err)
      NotificationManager.create({
        type: 'error',
        layout: NotificationLayout.CONDENSED,
        title: 'An error ocurred while updating the onboarding progress',
      })
    }
  }

  const classes = classNames('text-gray-500', {
    'text-base pb-4': variant === StepperType.PRIMARY,
    'text-sm px-6 pb-6 pt-4': variant === StepperType.SECONDARY,
  })

  return (
    <div className={classes}>
      <Content
        step={step}
        position={position}
        variant={variant}
        active={active}
        skip={isSkippable && skip}
        openNextItem={openNextItem}
      />
    </div>
  )
}
