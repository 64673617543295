import React from 'react'
import router from 'next/router'
import Link from 'next/link'
import classNames from 'classnames'
import 'react-loading-skeleton/dist/skeleton.css'

import { format } from 'date-fns'

import * as Glyph from '@tellimer/ui/Icon'
import { Table, Tbody, Td, Th, Thead } from '@tellimer/ui/Table'
import { Tooltip } from '@tellimer/ui/Tooltip'
import { useAppSelector } from 'hooks/reduxHooks'
import { selectHasPublishedPosts, selectPublishedPosts } from 'redux/post/selector'
import { usePostFlags } from 'hooks/postFlags'

export const RecentlyPublishedPosts = () => {
  const { getLastPublishedPost } = usePostFlags()

  const hasPublishedPosts = useAppSelector(selectHasPublishedPosts)
  const posts = useAppSelector(selectPublishedPosts)

  return (
    <div>
      {/* Posts */}
      {hasPublishedPosts && (
        <>
          <div className="flex items-center justify-between mt-8">
            <h3 className="text-lg text-gray-900 font-medium">Recently published posts</h3>
            <Link href="/posts">
              <a className="text-sm text-accent-600 font-medium cursor-pointer hover:opacity-80 transition">
                View all
              </a>
            </Link>
          </div>
          <Table wrapperClassName="mt-5 rounded-b-lg rounded-t-lg">
            <Thead>
              <tr>
                <Th>TITLE</Th>
                <Th>
                  <div className="flex items-center justify-end">
                    OPEN. %
                    <Tooltip
                      className="ml-2"
                      content={
                        <p className="text-white text-xs leading-4 font-medium">
                          The percentage of recipients who opened the email
                        </p>
                      }
                    >
                      <Glyph.Info className="w-5 text-gray-400 inline-block" />
                    </Tooltip>
                  </div>
                </Th>
                <Th>
                  <div className="flex items-center justify-end">
                    WEB VIEWS
                    <Tooltip
                      className="ml-2"
                      content={
                        <p className="text-white text-xs leading-4 font-medium">
                          The total number of web views for a post
                        </p>
                      }
                    >
                      <Glyph.Info className="w-5 text-gray-400 inline-block" />
                    </Tooltip>
                  </div>
                </Th>
                <Th>
                  <div className="flex items-center justify-end">
                    UNSUB. %
                    <Tooltip
                      className="ml-2"
                      content={
                        <p className="text-white text-xs leading-4 font-medium">
                          The percentage of recipients who unsubscribed from the email
                        </p>
                      }
                    >
                      <Glyph.Info className="w-5 text-gray-400 inline-block" />
                    </Tooltip>
                  </div>
                </Th>
              </tr>
            </Thead>
            <Tbody>
              {posts?.map(({ title, metrics, id, publishedAt }, idx) => (
                <tr
                  key={idx}
                  className={classNames('cursor-pointer', {
                    'bg-pink-50': id === getLastPublishedPost(),
                  })}
                  onClick={() => {
                    return router.push({ pathname: `/posts/${id}` }, undefined, { shallow: true })
                  }}
                >
                  <Td>
                    <div className="flex">
                      <p className="text-gray-900">{title}</p>
                      {id === getLastPublishedPost() && (
                        <div className="ml-2 text-pink-800 text-xs bg-pink-100 h-5 rounded px-2.5 flex items-center">
                          NEW
                        </div>
                      )}
                    </div>
                    <p className="text-sm text-gray-500">
                      {format(new Date(publishedAt || ''), 'MMMM do, YYYYY @ h:mm aaa')}
                    </p>
                  </Td>
                  <Td className="text-right">
                    {!metrics?.openPercentage ? '0%' : metrics.openPercentage}
                  </Td>
                  <Td className="text-right">{!metrics?.webViews ? '0%' : metrics.webViews}</Td>
                  <Td className="text-right">
                    {!metrics?.unsubscribePercentage ? '0%' : metrics.unsubscribePercentage}
                  </Td>
                </tr>
              ))}
            </Tbody>
          </Table>
        </>
      )}
    </div>
  )
}
