import React, { Fragment, useEffect } from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { fetchWhatsNext } from 'redux/dashboard/fetchWhatsNext'
import { selectFetchingWhatsNext, selectWhatsNext } from 'redux/dashboard/selector'

export const DashboardWhatsNext = () => {
  const dispatch = useAppDispatch()
  const fetchingWhatsNext = useAppSelector(selectFetchingWhatsNext)
  const whatsNext = useAppSelector(selectWhatsNext)

  useEffect(() => {
    dispatch(fetchWhatsNext())
  }, [dispatch])

  return (
    <div>
      <div className="flex items-center justify-between">
        <h3 className="text-lg text-gray-900 font-medium">What’s next?</h3>
        <a
          className="text-sm text-accent-600 font-medium cursor-pointer hover:opacity-80 transition"
          href={whatsNext ? whatsNext.viewAllUrl : '#'}
        >
          View all
        </a>
      </div>
      {fetchingWhatsNext ? (
        <Skeleton count={5} />
      ) : (
        <div className="flex flex-col border border-gray-200 rounded-md mt-5">
          {whatsNext && (
            <>
              {whatsNext?.links?.map(({ url, title, thumbnailUrl }, idx) => (
                <Fragment key={idx}>
                  <a
                    className="px-6 py-5 flex items-center hover:bg-gray-100 transition"
                    href={url}
                  >
                    <img className="w-10 h-10 object-cover" src={thumbnailUrl} alt="preview" />
                    <p className="ml-3 text-sm text-gray-900 font-medium">{title}</p>
                  </a>
                  {idx < (whatsNext?.links || []).length - 1 && (
                    <div className="border-b border-gray-200" />
                  )}
                </Fragment>
              ))}
            </>
          )}
        </div>
      )}
    </div>
  )
}
