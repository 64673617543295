import React, { useEffect } from 'react'
import 'react-loading-skeleton/dist/skeleton.css'

import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { getCreator, selectCreatorHasCompletedOnboarding } from 'redux/auth/selector'
import { fetchOnboarding } from 'redux/onboarding/fetchOnboarding'
import { fetchOnboardingProgress } from 'redux/onboardingProgress/fetchOnboardingProgress'
import { initOnboardingSubscription } from 'redux/onboardingProgress/initOnboardingSubscription'
import { DashboardAnalytics } from './DashboardAnalytics'
import { RecentlyPublishedPosts } from './RecentlyPublishedPosts'
import { DashboardWhatsNext } from './DashboardWhatsNext'
import { DashboardOnboarding } from './DashboardOnboarding'
import { NotificationLayout, NotificationManager } from '@tellimer/ui'

const Dashboard = () => {
  const dispatch = useAppDispatch()
  const creator = useAppSelector(getCreator)
  const hasCompletedOnboarding = useAppSelector(selectCreatorHasCompletedOnboarding)

  useEffect(() => {
    if (hasCompletedOnboarding) return
    ;(async () => {
      try {
        await Promise.all([
          dispatch(fetchOnboardingProgress()).unwrap(),
          dispatch(fetchOnboarding()).unwrap(),
          dispatch(initOnboardingSubscription()).unwrap(),
        ])
      } catch (err) {
        console.error('Something failed while loading onboarding details', err)
        NotificationManager.create({
          type: 'error',
          layout: NotificationLayout.CONDENSED,
          title: 'An error ocurred while retrieveing the onboading progress',
        })
      }
    })()
  }, [hasCompletedOnboarding])

  return (
    <>
      <div className="py-4 px-8 md:py-6 border-b border-gray-200">
        <h1 className="text-xl md:text-3xl text-gray-900 font-brick">
          {creator?.fullName ? `${creator?.fullName}'s ` : ''}Dashboard
        </h1>
      </div>
      <div className="flex items-stretch p-8 gap-8 flex-col lg:flex-row">
        <div className="flex-1">
          {/* Analytics */}
          <DashboardAnalytics />

          {/* Posts */}
          <RecentlyPublishedPosts />
        </div>

        <div className="w-81">
          {/* Concierge ad */}
          {/* <Concierge type="small" /> */}

          {/* Account setup guide */}
          <DashboardOnboarding />

          {/* What's next */}
          <DashboardWhatsNext />
        </div>
      </div>
    </>
  )
}

Dashboard.displayName = 'Dashboard'
export default Dashboard
