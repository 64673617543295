import { createAsyncThunk } from '@reduxjs/toolkit'

import { NotificationLayout, NotificationManager } from '@tellimer/ui'
import { importContacts } from 'gql/publication/mutations'

import { selectPublication } from '../publication/selector'
import { updateImportStatus, ImportStatus } from './updateImportStatus'
import { selectIsImportInProgress } from './selector'

export type ImportDetails = {
  fileDetails: any
  status: ImportStatus.NOT_STARTED
  totalContactsToImport: number
  totalSkippedContacts: number
  totalImportedContacts: number
}

/**
 * This is the action that will take a processed file and will start to get the subscribers from that
 * file into the publication.
 *
 */
export const importPendingSubscribers = createAsyncThunk(
  'importSubscribers/importPendingSubscribers',
  async (_, { getState, dispatch }) => {
    try {
      const state = getState() as any

      // This can't happen if we don't have an ongoing import process
      const isImportingInProgress = selectIsImportInProgress(state)
      if (!isImportingInProgress) return

      const publication = selectPublication(state)

      const { id: publicationId } = publication || {}

      dispatch(updateImportStatus(ImportStatus.IMPORTING))
      await importContacts(publicationId)
      // We don't need a response here since the result may take a while so the websocket should notify
      // when we are done
    } catch (err) {
      console.error(err)
      NotificationManager.create({
        type: 'error',
        layout: NotificationLayout.CONDENSED,
        title: 'An error occurred while importing your contacts',
      })
      dispatch(updateImportStatus(ImportStatus.ERROR_IMPORTING))
    }
  },
)
