import React, { useEffect, useState } from 'react'
import 'react-loading-skeleton/dist/skeleton.css'

import { StepperType } from 'types/onboarding.types'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import {
  selectIsOnboardingCompleted,
  selectIsOnboardingDismissed,
} from 'redux/onboardingProgress/selector'
import { dismissAccountSetup } from 'redux/onboardingProgress/dismissAccountSetup'

import { OnboardingStepper } from '../onboarding/OnboardingStepper'
import { NotificationLayout, NotificationManager } from '@tellimer/ui'

export const DashboardOnboarding = () => {
  const dispatch = useAppDispatch()
  const isOnboardingDismissed = useAppSelector(selectIsOnboardingDismissed)
  const isOnboardingCompleted = useAppSelector(selectIsOnboardingCompleted)
  const [showDashboardStepper, setShowDashboardStepper] = useState<boolean>(
    !isOnboardingCompleted && !isOnboardingDismissed,
  )

  const onDismissAccountSetupGuide = async () => {
    try {
      await dispatch(dismissAccountSetup()).unwrap()
    } catch (err) {
      console.error(err)
      NotificationManager.create({
        type: 'error',
        layout: NotificationLayout.CONDENSED,
        title: 'An error ocurred while updating the onboarding progress',
      })
    }
  }

  useEffect(() => {
    setShowDashboardStepper(!isOnboardingCompleted && !isOnboardingDismissed)
  }, [isOnboardingCompleted, isOnboardingDismissed])

  if (!showDashboardStepper) {
    return null
  }

  return (
    <div>
      <div className="flex items-baseline justify-between">
        <h3 className="text-lg text-gray-900 font-medium">Account setup guide</h3>
        <a
          className="text-sm text-accent-600 font-medium cursor-pointer hover:opacity-80 transition"
          onClick={onDismissAccountSetupGuide}
        >
          Dismiss
        </a>
      </div>
      <OnboardingStepper className="mt-5 mb-8" variant={StepperType.SECONDARY} />
    </div>
  )
}
