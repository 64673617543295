import React, { FC } from 'react'

// types
import { GrowthDataFeedType } from '../../types/dashboard.types'

export type GrowthChartTooltipProps = {
  payload?: any
  label?: string
  feed?: string
}

const GrowthChartTooltip: FC<GrowthChartTooltipProps> = ({ payload = [], label, feed }) => {
  const [p] = Array.isArray(payload) ? payload : []
  const netGrowth = (p?.payload?.subscribes || 0) - (p?.payload?.unsubscribes || 0)

  return (
    <div className="w-40 px-3 py-2 bg-white shadow-lg rounded-md">
      <p className="text-xs text-gray-600 font-medium">{label}</p>
      <div className="mt-2 flex flex-col gap-1">
        {/* Net Growth */}
        {feed === GrowthDataFeedType.OVERALL && (
          <div className="flex items-center justify-between">
            <span className="text-xs text-gray-900 font-semibold">Net Growth</span>
            <span
              className={
                'text-xs font-semibold ' + (netGrowth < 0 ? 'text-red-600' : 'text-accent-600')
              }
            >
              {netGrowth}
            </span>
          </div>
        )}

        {/* Subscribed */}
        {(feed === GrowthDataFeedType.OVERALL || feed === GrowthDataFeedType.SUBSCRIBES) && (
          <div className="flex items-center justify-between">
            <span className="text-xs text-gray-900 font-medium">Subscribed</span>
            <span className="text-xs text-accent-600 font-medium">
              {p?.payload?.subscribes || 0}
            </span>
          </div>
        )}

        {/* Unsubscribed */}
        {(feed === GrowthDataFeedType.OVERALL || feed === GrowthDataFeedType.UNSUBSCRIBES) && (
          <div className="flex items-center justify-between">
            <span className="text-xs text-gray-900 font-medium">Unsubscribed</span>
            <span className="text-xs text-red-600 font-medium">
              {p?.payload?.unsubscribes || 0}
            </span>
          </div>
        )}
      </div>
    </div>
  )
}

export { GrowthChartTooltip }
