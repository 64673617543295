import React from 'react'
import classNames from 'classnames'

import { ButtonDefault, ButtonSecondary } from '@tellimer/ui/Button'
import { Upload } from '@tellimer/ui/Upload'
import { ImportContacts, ImportStatus, ImportVariant } from '@tellimer/ui/ImportContacts'

import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { OnboardingStepTypes, StepperType } from 'types/onboarding.types'
import { completeOnboardingStep } from 'redux/onboardingProgress/slice'
import { uploadSubscribers } from 'redux/importSubscribers/uploadSubscribers'
import { selectImportDetails, selectIsImportInProgress } from 'redux/importSubscribers/selector'
import { importPendingSubscribers } from 'redux/importSubscribers/importPendingSubscribers'
import { cancelPendingImport } from 'redux/importSubscribers/cancelPendingImport'

type Props = {
  step: OnboardingStepTypes
  skip: CallableFunction
  variant: StepperType
}

export const AddContacts = ({ step, variant, skip }: Props) => {
  const dispatch = useAppDispatch()

  const isImportingInProgress = useAppSelector(selectIsImportInProgress)
  const importDetails = useAppSelector(selectImportDetails)
  const { status: importState = ImportStatus.NOT_STARTED } = importDetails

  const handleAddContacts = async (file: File) => {
    if (!file) return
    dispatch(uploadSubscribers(file))
  }

  const handleImportContacts = async () => {
    dispatch(importPendingSubscribers())
  }

  const onCancel = () => {
    dispatch(cancelPendingImport())
  }

  const onDismiss = () => {
    dispatch(cancelPendingImport())
    dispatch(completeOnboardingStep(step))
  }

  const classes = {
    btnGroup: classNames({
      'mt-6 flex gap-4': true,
      'flex-col items-stretch md:items-center md:flex-row': variant === StepperType.PRIMARY,
      'flex-col': variant === StepperType.SECONDARY,
    }),
  }

  return (
    <>
      {!isImportingInProgress && (
        <div>
          <Upload onChange={handleAddContacts} />
          <p className="mt-2 text-sm text-gray-500">
            Import your existing contacts using a CSV file that has at least one column with email
            addresses.{' '}
            <a
              href="https://tellimer.notion.site/Common-issues-when-importing-subscribers-ad621e73c518494c84b99c6b803a8ba4"
              data-testid="contacts-step-help-link"
              target="_blank"
              rel="noreferrer"
            >
              For help click here.
            </a>
          </p>
        </div>
      )}
      {isImportingInProgress && (
        <ImportContacts
          variant={ImportVariant.STEPPER}
          status={importState || ImportStatus.PROCESSING}
          availableContacts={importDetails.totalContactsToImport}
          skippedContacts={importDetails.totalSkippedContacts}
          onImport={handleImportContacts}
          onDismiss={onDismiss}
          onCancel={onCancel}
        />
      )}
      <div className={classes.btnGroup}>
        <ButtonSecondary
          className="h-9.5"
          type="button"
          onClick={handleImportContacts}
          disabled={importState !== ImportStatus.PROCESSED}
          data-testid="contacts-step-import-contacts-btn"
        >
          Import contacts
        </ButtonSecondary>
        <ButtonDefault onClick={() => skip()} data-testid="contacts-step-skip-step-btn">
          Skip this step
        </ButtonDefault>
      </div>
    </>
  )
}

AddContacts.displayName = 'AddContacts'
