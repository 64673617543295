import Cookies, { CookieAttributes } from 'js-cookie'

export function useCookies() {
  const setCookies = (field: string, value: string, options?: CookieAttributes): void => {
    Cookies.set(field, value, options)
  }

  const getCookies = (field: string): string | undefined => {
    return Cookies.get(field)
  }

  return {
    setCookies,
    getCookies,
  }
}
