import { createAsyncThunk } from '@reduxjs/toolkit'
import { AppState } from 'redux/store'
import { OnboardingProgress, OnboardingStepTypes } from 'types/onboarding.types'
import { selectOnboardingProgress } from './selector'
import { updateUserOnboardingProgress } from './updateOnboardingProgress'

export const skipStep = createAsyncThunk(
  'onboardingProgress/skipStep',
  async (step: OnboardingStepTypes, { getState, dispatch }) => {
    const state = getState() as AppState
    const progress = selectOnboardingProgress(state) || {}

    const stepProgress = progress[step] || {}

    const payload = {
      ...progress,
      [step]: {
        ...stepProgress,
        isCompleted: false,
        isSkipped: true,
      },
    } as OnboardingProgress

    await dispatch(updateUserOnboardingProgress(payload)).unwrap()
  },
)
