import { useCookies } from './cookies'

export function usePostFlags() {
  const { setCookies, getCookies } = useCookies()

  const setPublishedPosts = (postIDsString: string): void => {
    setCookies('lastPublishedPost', postIDsString, { expires: 2 })
  }

  const getPublishedPosts = (): string[] => {
    const postIDsString = getCookies('lastPublishedPost')
    let postIds: string[] = []
    if (postIDsString) {
      postIds = postIDsString.split(',')
    }
    return postIds
  }

  const getLastPublishedPost = (): string | undefined => {
    const postIDs: string[] = getPublishedPosts()
    return postIDs.length > 0 ? postIDs[postIDs.length - 1] : undefined
  }

  const pushPublishedPost = (postId: string): void => {
    const postIDs: string[] = getPublishedPosts()
    if (!postIDs.includes(postId)) {
      postIDs.push(postId)
    }
    setPublishedPosts(postIDs.join(','))
  }

  return {
    setPublishedPosts,
    getPublishedPosts,
    getLastPublishedPost,
    pushPublishedPost,
  }
}
