import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
// This import is done like this to avoid compilation issues in Next 122
// @ts-ignore
import { yupResolver } from '@hookform/resolvers/yup'
import { ButtonDefault, ButtonSecondary } from '@tellimer/ui/Button'
import { InputGroup } from '@tellimer/ui/Input'
import { ColorPicker } from '@tellimer/ui/ColorPicker'
import { ImageUpload } from '@tellimer/ui'
import { uploadFile, ScriberFileType } from '@tellimer/file-upload'

import { useAppSelector, useAppDispatch } from 'hooks/reduxHooks'
import { selectPublication } from 'redux/publication/selector'
import { updatePublicationCustomisation } from 'redux/publication/updatePublicationCustomisation'
import { completeOnboardingStep } from 'redux/onboardingProgress/slice'
import { StepperType, OnboardingStepTypes } from 'types/onboarding.types'
import { getClient, GQL_CLIENT_TYPES } from 'gql'

type BrandingFormValues = {
  accentColor: string
  logo: string
}

type Props = {
  step: OnboardingStepTypes
  skip: CallableFunction
  variant: StepperType
}

const FORM_SCHEMA = yup.object({
  accentColor: yup.string().required('This field is required.'),
  logo: yup.string().nullable(),
})

export const BrandingSettings = ({ step, variant, skip }: Props) => {
  const dispatch = useAppDispatch()
  const publication = useAppSelector(selectPublication)
  const { customisation = {} } = publication
  const gqlClient = getClient(GQL_CLIENT_TYPES.PRIVATE)

  const {
    handleSubmit,
    formState: { errors: brandingErrors },
    setValue,
    getValues,
  } = useForm<BrandingFormValues>({
    resolver: yupResolver(FORM_SCHEMA),
    reValidateMode: 'onChange',
    defaultValues: {
      accentColor: customisation?.accentColor || '#000000',
      logo: customisation?.logo,
    },
  })

  const onColorChange = (value: any) => {
    const { hex } = value
    setValue('accentColor', hex)
  }

  const [uploading, setUploading] = useState<boolean>(false)
  const [uploadError, setUploadError] = useState<string>('')

  const onImageChange = async (file: File) => {
    setUploadError('')

    if (!file) {
      setValue('logo', '')
      return
    }

    setUploading(true)

    try {
      const { url } = await uploadFile(file, gqlClient, {
        fileType: ScriberFileType.PUBLICATION_LOGO,
        publicationId: publication.id,
      })
      setValue('logo', url)
    } catch (e) {
      // FIXME: Wire this to error notification
      console.error(e)
      setUploadError('Something went wrong. Please try again!')
    } finally {
      setUploading(false)
    }
  }

  const onSubmit = (data: any) => {
    dispatch(completeOnboardingStep(step))
    dispatch(updatePublicationCustomisation(data))
  }

  const { accentColor, logo } = getValues()

  const classes = {
    btnGroup: classNames({
      'mt-6 flex gap-4': true,
      'flex-col items-stretch md:items-center md:flex-row': variant === StepperType.PRIMARY,
      'flex-col': variant === StepperType.SECONDARY,
    }),
  }

  useEffect(() => {
    if (brandingErrors.logo?.message) {
      setUploadError(brandingErrors.logo.message)
    } else {
      setUploadError('')
    }
  }, [brandingErrors.logo])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <InputGroup
        label="Accent colour"
        hasError={!!brandingErrors.accentColor}
        errorMsg={brandingErrors.accentColor?.message}
      >
        <ColorPicker className="w-full" initialValue={accentColor} onChangeColor={onColorChange} />
        <p className="mt-2 text-sm text-gray-500">
          Primary color used in your publication for buttons etc.
        </p>
      </InputGroup>
      <InputGroup
        className="mt-6"
        label="Publication logo"
        bottomHint="The primary logo for your brand displayed across your theme, should be transparent and at
        least 600px x 72px."
        hasError={!!uploadError}
        errorMsg={uploadError}
      >
        <ImageUpload imageUrl={logo} onChange={onImageChange} uploading={uploading} />
      </InputGroup>
      <div className={classes.btnGroup}>
        <ButtonSecondary
          type="submit"
          className="h-9.5"
          data-testid="branding-step-save-btn"
          disabled={uploading}
        >
          Save and continue
        </ButtonSecondary>
        <ButtonDefault
          onClick={() => skip()}
          data-testid="branding-step-skip-step-btn"
          disabled={uploading}
        >
          Skip this step
        </ButtonDefault>
      </div>
    </form>
  )
}

BrandingSettings.displayName = 'BrandingSettings'
