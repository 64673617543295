import type { AppState } from '../store'

export const selectDashboard = (state: AppState) => state.dashboard

export const selectFetchingAvailableIntervals = (state: AppState) =>
  state.dashboard.featchingAvailableIntervals
export const selectFetchingAnalytics = (state: AppState) => state.dashboard.featchingAnalytics
export const selectFetchingWhatsNext = (state: AppState) => state.dashboard.featchingWhatsNext

export const selectAvailableIntervalsStatus = (state: AppState) =>
  state.dashboard.availableIntervalsStatus
export const selectAnalyticsStatus = (state: AppState) => state.dashboard.analyticsStatus
export const selectWhatsnextStatus = (state: AppState) => state.dashboard.whatsnextStatus
export const selectAnaltyicsDuration = (state: AppState) => state.dashboard.duration
export const selectAnaltyicsGrowthFeed = (state: AppState) => state.dashboard.growthFeed
export const selectAvailableIntervals = (state: AppState) => state.dashboard.availableIntervals
export const selectAnalytics = (state: AppState) => state.dashboard.analytics
export const selectWhatsNext = (state: AppState) => state.dashboard.whatsnext
