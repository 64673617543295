import React from 'react'
import classNames from 'classnames'

import { Check } from '@tellimer/ui/Icon'
import { AccordionItemChildrenProps } from '@tellimer/ui/Accordion'

import {
  Onboarding,
  OnboardingStep,
  StepperType,
  OnboardingStepTypes,
} from 'types/onboarding.types'

import { useAppSelector } from 'hooks/reduxHooks'

import { selectOnboardingProgress } from 'redux/onboardingProgress/selector'
import { getOnboarding } from 'redux/onboarding/selector'

export type OnboardingStepHeaderProps = AccordionItemChildrenProps & {
  step: OnboardingStepTypes
  title: string
  active?: boolean
  variant?: StepperType
}

export const OnboardingStepHeader = ({
  step,
  title,
  position = 0,
  active = true,
  variant = StepperType.PRIMARY,
}: OnboardingStepHeaderProps) => {
  const onboarding = useAppSelector(getOnboarding) as Onboarding
  const stepInfo = onboarding[step as keyof typeof onboarding] || {}
  const { timeToFillOut } = stepInfo as OnboardingStep

  const onboardingProgress = useAppSelector(selectOnboardingProgress) || {}
  const progress = onboardingProgress[step as keyof typeof onboardingProgress] || {}
  const { isCompleted = false } = progress as any

  const classes = {
    step: classNames(
      'flex items-center justify-center w-10 h-10 rounded-full border-2 font-medium text-sm transition',
      {
        'text-gray-500 group-hover:text-gray-900 border-gray-300 group-hover:border-gray-400':
          !active && !isCompleted,
        'text-accent-600 group-hover:text-accent-800 border-accent-600 group-hover:border-accent-800':
          active && !isCompleted,
        'border-accent-600 bg-accent-600': isCompleted,
      },
    ),
    titleWrapper: classNames('flex ml-4', {
      'flex-col items-start md:items-center md:flex-row justify-between flex-1 ':
        variant === StepperType.PRIMARY,
      'flex-col': variant === StepperType.SECONDARY,
    }),
    title: classNames('font-medium lg:font-normal transition', {
      'text-gray-500 group-hover:text-gray-900': !active && !isCompleted,
      'text-accent-600 group-hover:text-accent-800': active && !isCompleted,
      'text-gray-900 group-hover:text-accent-600': isCompleted,
      'text-base md:text-2xl': variant === StepperType.PRIMARY,
      'text-normal mb-1': variant === StepperType.SECONDARY,
    }),
    timeToFillOut: classNames('transition group-hover:text-gray-900', {
      'text-gray-500': !active,
      'text-gray-900': active,
      'text-sm mt-1 md:mt-0': variant === StepperType.PRIMARY,
      'text-xs': variant === StepperType.SECONDARY,
    }),
  }

  return (
    <div className="flex items-center flex-1 md:pr-6">
      <div
        className={classNames('flex items-center flex-1 ', {
          'md:pr-6': variant === StepperType.PRIMARY,
          'pr-3': variant === StepperType.SECONDARY,
        })}
      >
        <span className={classes.step}>
          {isCompleted ? <Check className="w-3.5 text-white" /> : position + 1}
        </span>
        <div className={classes.titleWrapper}>
          <p className={classes.title} data-testid={`step-title-${position}`}>
            {title}
          </p>
          <span className={classes.timeToFillOut}>{timeToFillOut}</span>
        </div>
      </div>
    </div>
  )
}
