import * as Glyph from '@tellimer/ui/Icon'
import { Tooltip } from '@tellimer/ui/Tooltip'
import React from 'react'
import { Line, LineChart } from 'recharts'
import { format } from 'date-fns'
import classNames from 'classnames'

type Metric = {
  name: string
  description: string
  chartData: any[]
  currentValue: string
  showChart: boolean
  showLastChangedAt: boolean
  change: {
    value: string
    direction: string
  }
  lastChangedAt: string
}

export function KeyMetric(metric: Metric) {
  const lastChangedAt = new Date(metric.lastChangedAt)
  const chartContainerClasses = classNames('flex items-center mt-3', {
    'justify-between': !metric.showChart,
  })
  return (
    <div className="flex-1 flex items-center justify-left h-38 p-6 border border-gray-200 rounded-md">
      <div className="w-full">
        <div className="flex items-center text-base text-gray-500">
          {metric.name}
          <Tooltip
            className="ml-1"
            content={
              <p className="text-white text-xs leading-4 font-medium">{metric.description}</p>
            }
          >
            <Glyph.Info className="w-5 text-gray-400 inline-block" />
          </Tooltip>
        </div>

        <div className={chartContainerClasses}>
          <span className="text-4xl text-gray-900">{metric.currentValue}</span>
          {metric.showChart ? (
            <div className="ml-4">
              <LineChart width={64} height={50} data={metric.chartData}>
                <Line
                  type="monotone"
                  dataKey="value"
                  stroke="#059669"
                  strokeWidth={2}
                  dot={false}
                />
              </LineChart>
            </div>
          ) : (
            <div className="ml-2 mr-2">
              <Glyph.DashedLine />
            </div>
          )}
        </div>
        {!metric.showLastChangedAt && (
          <div className="ml-1 mt-2 flex items-center">
            {metric.currentValue === '0%' && <Glyph.GreyCircle />}
            {metric.showChart ? (
              <>
                {metric.change.direction === 'up' && (
                  <Glyph.CaretUp className="text-accent-400 w-2.5" />
                )}
                {metric.change.direction === 'down' && (
                  <Glyph.CaretDown className="text-red-500 w-2.5" />
                )}
                {metric.change.direction === 'up' && (
                  <span className="ml-1.5 text-accent-600 text-sm font-semibold">
                    {metric.change.value}
                  </span>
                )}
                {metric.change.direction === 'down' && (
                  <span className="ml-1.5 text-red-500 text-sm font-semibold">
                    {metric.change.value}
                  </span>
                )}
              </>
            ) : null}

            {metric.currentValue === '0%' && (
              <span className="ml-1.5 text-gray-600 text-sm font-semibold">0</span>
            )}
          </div>
        )}
        {metric.showLastChangedAt && (
          <div className="text-sm text-gray-500">As of {format(lastChangedAt, 'HH:mm, MMM d')}</div>
        )}
      </div>
    </div>
  )
}
