import { createAsyncThunk } from '@reduxjs/toolkit'
import { updateOnboardingProgressSubscription } from 'gql/dashboard'
import { getCreator } from 'redux/auth/selector'
import { Creator } from 'types/creator.types'
import { NotificationLayout, NotificationManager } from '@tellimer/ui'
import { updateOnboardingProgressFromSubscription } from './slice'
import { subscription } from 'gql'

export const initOnboardingSubscription = createAsyncThunk(
  'onboardingProgress/initOnboardingSubscription',
  async (_, { getState, dispatch }) => {
    const state = getState() as any
    const creator = getCreator(state) as Creator

    subscription(
      updateOnboardingProgressSubscription,
      { publicationId: creator.publication.id },
      async (res: any) => {
        try {
          if (res.error) {
            throw new Error(res.error)
          }

          const { onboardingProgressUpdated } = res.data
          console.log('>>> Updated onboarding progress', onboardingProgressUpdated)
          dispatch(updateOnboardingProgressFromSubscription(onboardingProgressUpdated))
        } catch (err) {
          console.error(err)
          NotificationManager.create({
            type: 'error',
            layout: NotificationLayout.CONDENSED,
            title: 'An error occurred while getting your onboarding progress',
          })
          return
        }
      },
    )
  },
)
