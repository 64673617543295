import React, { useEffect } from 'react'
import classNames from 'classnames'

import { CircularProgressBar } from '@tellimer/ui/CircularProgressBar'
import { Avatar } from '@tellimer/ui/Avatar'
import { Accordion, AccordionSize } from '@tellimer/ui/Accordion'
import { LoadingBar } from '@tellimer/ui/LoadingBar'

import { StepperType, OnboardingStepTypes } from 'types/onboarding.types'
import { Creator } from 'types/creator.types'

import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'

import { getCreator } from 'redux/auth/selector'
import { getOnboarding } from 'redux/onboarding/selector'
import {
  selectOnboardingProgress,
  selectOnboardingCurrentStep,
} from 'redux/onboardingProgress/selector'
import { setOnboardingCurrentStep, ONBOARDING_STEP_SEQUENCE } from 'redux/onboardingProgress/slice'

import { OnboardingStepHeader } from './OnboardingStepHeader'
import { OnboardingStepContent } from './OnboardingStepContent'
import {
  PublicationDetails,
  BrandingSettings,
  AddContacts,
  VerifyEmail,
  PublishPost,
  GettingPaid,
} from './steps'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { LDFlags } from 'types/common.types'
import { useRouter } from 'next/router'
import { initPaymentsSettings, setupStripeConnect } from 'redux/payments/actions'
import { NotificationLayout, NotificationManager } from '@tellimer/ui'

export type OnboardingStepperStepInfo = {
  title: string
  isSkippable: boolean
  content: React.FC<any>
  disabled?: ({ creator }: { creator: Partial<Creator> }) => boolean
  shouldShow?: ({ flags }: { flags: LDFlags }) => boolean
}

const steps = {
  [OnboardingStepTypes.PUBLICATION]: {
    title: 'Publication details',
    isSkippable: true,
    content: PublicationDetails,
  },
  [OnboardingStepTypes.BRANDING]: {
    title: 'Add your branding',
    isSkippable: true,
    content: BrandingSettings,
  },
  [OnboardingStepTypes.CONTACTS]: {
    title: 'Add your contacts',
    isSkippable: true,
    content: AddContacts,
  },
  [OnboardingStepTypes.EMAIL]: {
    title: 'Verify your email',
    isSkippable: true,
    content: VerifyEmail,
    disabled: ({ creator }) => !!creator.hasVerifiedEmail,
  },
  [OnboardingStepTypes.PAYMENT]: {
    title: 'Getting paid',
    isSkippable: true,
    content: GettingPaid,
    shouldShow: ({ flags: { showPayments } }) => showPayments,
  },
  [OnboardingStepTypes.POST]: {
    title: 'Publish your first post',
    isSkippable: false,
    content: PublishPost,
  },
} as Record<OnboardingStepTypes, OnboardingStepperStepInfo>

export const OnboardingStepper = ({
  className,
  variant,
}: {
  className?: string
  variant?: StepperType
}) => {
  const dispatch = useAppDispatch()
  const router = useRouter()
  const creator = useAppSelector(getCreator)
  const progress = useAppSelector(selectOnboardingProgress)
  const currentStep = useAppSelector(selectOnboardingCurrentStep)
  const onboardingInfo = useAppSelector(getOnboarding)

  const flags = useFlags()

  const onAccordionChange = (event: Event, stepId: string, position: number) => {
    event.preventDefault()

    dispatch(setOnboardingCurrentStep(position))
    const step = document.getElementById(stepId)

    window.scrollTo({
      top: step?.offsetTop,
      behavior: 'smooth',
    })
  }

  const classes = {
    container: classNames({ 'flex flex-col': true }, className),
    header: classNames('flex items-center', {
      'md:ml-6': variant === StepperType.PRIMARY,
      'px-6 py-4 bg-gray-50 border border-gray-200 border-b-0 rounded-t-md':
        variant === StepperType.SECONDARY,
    }),
    percentageText: classNames('text-gray-900', {
      'ml-2 text-lg md:text-2xl': variant === StepperType.PRIMARY,
      'ml-3 text-sm font-medium': variant === StepperType.SECONDARY,
    }),
    content: classNames({
      'mt-8 md:mt-12': variant === StepperType.PRIMARY,
    }),
    accordion: classNames({
      'rounded-t-none': variant === StepperType.SECONDARY,
    }),
    accordionHeader: classNames({
      'p-6': variant === StepperType.PRIMARY,
      'px-6 py-4': variant === StepperType.SECONDARY,
    }),
  }

  const accordionItems = ONBOARDING_STEP_SEQUENCE.filter(step => {
    // Make sure the sequence matches the details
    const stepDetails = steps[step]
    if (!stepDetails) return false

    // Make sure we only show what we should based on flags
    const { shouldShow } = stepDetails
    return !shouldShow || shouldShow({ flags })
  }).map(step => {
    const { title, isSkippable, content, disabled } = steps[step]

    return {
      stepId: step,
      disabled: disabled ? disabled({ creator }) : false,
      headerClasses: classes.accordionHeader,
      Header: <OnboardingStepHeader title={title} step={step} variant={variant} />,
      Content: (
        <OnboardingStepContent
          step={step}
          variant={variant}
          isSkippable={isSkippable}
          Content={content}
        />
      ),
    }
  })

  useEffect(() => {
    ;(async () => {
      try {
        const { code } = router.query
        if (code) {
          dispatch(
            setOnboardingCurrentStep(Object.keys(steps).indexOf(OnboardingStepTypes.PAYMENT)),
          )
          await dispatch(setupStripeConnect(code as string)).unwrap()

          // We now remove from the url thw two items on the query that come from stripe
          const searchParams = new URLSearchParams(window.location.search)
          searchParams.delete('code')
          searchParams.delete('scope')

          const newUrl = new URL(window.location.href)
          newUrl.search = searchParams.toString()
          router.replace(newUrl)
        }
        const fromStepper = true
        await dispatch(initPaymentsSettings(fromStepper)).unwrap()
      } catch (error) {
        console.error(error)
        NotificationManager.create({
          type: 'error',
          layout: NotificationLayout.CONDENSED,
          title: 'An error occurred while setting up the payment',
        })
      }
    })()
  }, [router])

  // We don't want to show empty data here.
  // FIXME: In the future we may want to show some skeleton instead of just the loader
  // but for now this should do
  if (!progress || !onboardingInfo) {
    return (
      <div className="w-full my-20 flex justify-center items-center">
        <LoadingBar size="lg" />
      </div>
    )
  }

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <CircularProgressBar
          size={48}
          radius={20}
          percent={parseInt(progress?.completedPercentage || '0')}
          strokeWidth={2}
          strokeColor="text-accent-500"
          trailColor="text-gray-200"
        >
          <Avatar url={creator?.profilePicture} />
        </CircularProgressBar>
        <p className={classes.percentageText}>
          Your account is <span className="font-semibold">{progress?.completedPercentage}</span>{' '}
          complete.
        </p>
      </div>

      <div className={classes.content}>
        <Accordion
          size={variant === StepperType.PRIMARY ? AccordionSize.BIG : AccordionSize.COMPACT}
          className={classes.accordion}
          defaultActive={ONBOARDING_STEP_SEQUENCE.indexOf(currentStep)}
          onActiveItemChange={onAccordionChange}
          items={accordionItems}
        />
      </div>
    </div>
  )
}
