import { createAsyncThunk } from '@reduxjs/toolkit'

import { NotificationLayout, NotificationManager } from '@tellimer/ui'
import { cancelContactsImport } from 'gql/publication/mutations'

import { selectPublication } from '../publication/selector'
import { updateImportStatus, ImportStatus } from './updateImportStatus'
import { selectImportState, selectIsImportInProgress } from './selector'
import { setIsImportInProgress } from './uploadSubscribers'

export const cancelPendingImport = createAsyncThunk(
  'importSubscribers/cancelPendingImport',
  async (_, { getState, dispatch }) => {
    try {
      const state = getState() as any

      // This can't happen if we don't have an ongoing import process
      const isImportingInProgress = selectIsImportInProgress(state)
      if (!isImportingInProgress) return

      const status = selectImportState(state)
      // Errors are also not in need of cancellation, resetting the redux state should be enough
      const hasFailed = ['ERROR_PROCESSING', 'ERROR_IMPORTING'].includes(status)
      const isDone = ['IMPORTED', 'CANCELLED'].includes(status)
      if (hasFailed || isDone) {
        dispatch(setIsImportInProgress(false))
        dispatch(updateImportStatus(ImportStatus.NOT_STARTED))
        return
      }

      const publication = selectPublication(state)

      const { id: publicationId } = publication || {}

      await cancelContactsImport(publicationId)
    } catch (err) {
      console.error(err)
      NotificationManager.create({
        type: 'error',
        layout: NotificationLayout.CONDENSED,
        title: 'An error occurred while cancelling the import of your contacts',
      })
      dispatch(updateImportStatus(ImportStatus.ERROR_IMPORTING))
    }
  },
)
