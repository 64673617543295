import React from 'react'
import classNames from 'classnames'

import { ButtonDefault } from '@tellimer/ui/Button'
import { LoadingBar } from '@tellimer/ui/LoadingBar'
import { SpeakerPhone } from '@tellimer/ui/Icon'
import { NotificationManager, NotificationLayout } from '@tellimer/ui/Notification'

import { OnboardingStepTypes, StepperType } from 'types/onboarding.types'
import { sendVerificationEmail } from 'redux/onboardingProgress/slice'
import { selectIsSendingVerifyEmail } from 'redux/onboardingProgress/selector'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'

type Props = {
  step: OnboardingStepTypes
  skip: CallableFunction
  variant: StepperType
}

export const VerifyEmail = ({ variant, skip }: Props) => {
  const dispatch = useAppDispatch()
  const isSendingVerifyEmail = useAppSelector(selectIsSendingVerifyEmail)

  const sendVerificationLink = async () => {
    try {
      await dispatch(sendVerificationEmail()).unwrap()
      NotificationManager.create({
        type: 'success',
        layout: NotificationLayout.CONDENSED,
        title: 'Check your inbox for an activation link',
      })
    } catch (error) {
      console.error(error)
      NotificationManager.create({
        type: 'error',
        layout: NotificationLayout.CONDENSED,
        title: 'An error occurred while sending the verification email',
      })
    }
  }

  const classes = {
    btnGroup: classNames({
      'mt-6 flex gap-4': true,
      'flex-col items-stretch md:items-center md:flex-row': variant === StepperType.PRIMARY,
      'flex-col': variant === StepperType.SECONDARY,
    }),
    btnSingular: classNames('w-full md:w-auto', {
      'h-9.5 w-full': variant === StepperType.SECONDARY,
    }),
    activationDescription: classNames({
      'mt-2 text-gray-900 font-medium text-center': true,
      'text-base': variant === StepperType.PRIMARY,
      'text-sm': variant === StepperType.SECONDARY,
    }),
  }

  return (
    <div>
      <div className="py-8 pb-10 border border-gray-300 rounded-lg flex flex-col items-center ">
        <SpeakerPhone className="w-8 text-gray-400" />
        <p className={classes.activationDescription}>
          We've sent an activation link to your inbox.
        </p>
        <p className="mt-4 text-sm text-gray-500 font-medium">Can’t find the email?</p>
        <ButtonDefault
          disabled={isSendingVerifyEmail}
          onClick={sendVerificationLink}
          className="mt-2"
          data-testid="email-step-resend-link-btn"
        >
          Resend activation link
        </ButtonDefault>
        {isSendingVerifyEmail && <LoadingBar className="mt-4" size="md" />}
      </div>
      <div className="mt-6">
        <ButtonDefault
          className={classes.btnSingular}
          onClick={() => skip()}
          data-testid="email-step-skip-step-btn"
        >
          Skip this step
        </ButtonDefault>
      </div>
    </div>
  )
}

VerifyEmail.displayName = 'VerifyEmail'
