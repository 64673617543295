import { createAsyncThunk } from '@reduxjs/toolkit'
import { updatePublicationMutation } from 'gql/publication/mutations'
import { selectPublication } from './selector'

export const updatePublicationCustomisation = createAsyncThunk(
  'publication/updatePublicationCustomisation',
  async ({ accentColor, logo }: { accentColor: string; logo: string }, { getState }) => {
    const state = getState() as any
    const publication = selectPublication(state) || {}

    return await updatePublicationMutation(publication.id, {
      customisation: {
        accentColor,
        logo,
      },
    })
  },
)
