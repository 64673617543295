import React, { useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import classNames from 'classnames'
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip as ChartTooltip,
  XAxis,
  YAxis,
} from 'recharts'

import { Dropdown, DropdownOption, DropdownType } from '@tellimer/ui/Dropdown'
import * as Glyph from '@tellimer/ui/Icon'
import { Tooltip } from '@tellimer/ui/Tooltip'
import { RadioGroup } from '@tellimer/ui/headless'
import { GrowthChartTooltip } from './GrowthChartTooltip'
import { KeyMetric } from './KeyMetric'
import { GrowthDataFeedType, GrowthDataType } from 'types/dashboard.types'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { fetchAvailableIntervals } from 'redux/dashboard/fetchAvailableIntervals'
import { fetchAnalytics } from 'redux/dashboard/fetchAnalytics'
import { setDuration, setGrowthFeed } from 'redux/dashboard/slice'
import {
  selectFetchingAvailableIntervals,
  selectFetchingAnalytics,
  selectAnaltyicsDuration,
  selectAnaltyicsGrowthFeed,
  selectAvailableIntervals,
  selectAnalytics,
} from 'redux/dashboard/selector'
import { selectPublication } from 'redux/publication/selector'

export const DashboardAnalytics = () => {
  const dispatch = useAppDispatch()
  const fetchingAvailableIntervals = useAppSelector(selectFetchingAvailableIntervals)
  const fetchingAnalytics = useAppSelector(selectFetchingAnalytics)
  const selectedDuration = useAppSelector(selectAnaltyicsDuration)
  const selectedGrowthFeed = useAppSelector(selectAnaltyicsGrowthFeed)
  const availableIntervals = useAppSelector(selectAvailableIntervals)
  const analytics = useAppSelector(selectAnalytics)
  const publication = useAppSelector(selectPublication)

  const [durations, setDurations] = useState<DropdownOption[]>([])

  useEffect(() => {
    dispatch(fetchAvailableIntervals({}))
  }, [dispatch])

  useEffect(() => {
    if (availableIntervals && availableIntervals.length > 0) {
      const options = availableIntervals
        .map(item => ({ value: item.value, label: item.name }))
        .filter(_item => parseInt(_item.value, 10) > 0)
      setDurations(options)
      if (options.length > 0) {
        dispatch(setDuration(options[0]))
      }
    }
  }, [dispatch, availableIntervals])

  useEffect(() => {
    if (selectedDuration) {
      dispatch(fetchAnalytics({ duration: selectedDuration, publicationId: publication.id }))
    }
  }, [dispatch, selectedDuration])

  const onGrowthFeedChange = (feed: string) => {
    dispatch(setGrowthFeed(feed))
  }

  const onDurationChange = (option: DropdownOption) => {
    dispatch(setDuration(option))
  }

  const getGrowthGradientOffset = (data: GrowthDataType[], feed: GrowthDataFeedType) => {
    const dataMax = Math.max(...data.map(i => i[feed]))
    const dataMin = Math.min(...data.map(i => i[feed]))
    if (dataMax <= 0) {
      return 0
    }
    if (dataMin >= 0) {
      return 1
    }
    return dataMax / (dataMax - dataMin)
  }

  const classes = {
    growthFeed: (active: boolean, checked: boolean) =>
      classNames({
        'flex items-center px-3 py-1 border border-gray-300 rounded-xl transition hover:bg-gray-100 cursor-pointer':
          true,
        'bg-gray-200': active || checked,
      }),
  }

  return (
    <div>
      {/* Analytics */}
      <div className="flex items-center justify-between">
        <h3 className="text-lg text-gray-900 font-medium">Your analytics</h3>
        <Dropdown
          variant={DropdownType.SECONDARY}
          selected={selectedDuration}
          options={durations}
          onChange={onDurationChange}
        />
      </div>
      {fetchingAvailableIntervals || fetchingAnalytics ? (
        <div>
          <div className="grid grid-rows-3 grid-cols1 lg:grid-cols-3 lg:grid-rows-1 gap-5 p-5">
            <Skeleton count={3} />
            <Skeleton count={3} />
            <Skeleton count={3} />
          </div>
          <div className="p-5">
            <Skeleton count={6} />
          </div>
        </div>
      ) : (
        <>
          <div className="flex mt-3 gap-4 flex-col lg:flex-row">
            {analytics?.keyMetrics?.map(
              (analytic: any) =>
                analytic && <KeyMetric key={`key-metric-${analytic.name}`} {...analytic} />,
            )}
          </div>
          <div className="mt-5 p-6 border border-gray-200 rounded-md">
            <div className="flex items-start justify-between flex-col lg:flex-row">
              <div className="flex items-center text-base text-gray-500">
                {analytics?.chartMetric?.name}
                <Tooltip
                  className="ml-1"
                  content={
                    <p className="text-white text-xs leading-4 font-medium">
                      {analytics?.chartMetric?.description}
                    </p>
                  }
                >
                  <Glyph.Info className="w-5 text-gray-400 inline-block" />
                </Tooltip>
              </div>

              <RadioGroup
                value={selectedGrowthFeed}
                onChange={(val: string) => onGrowthFeedChange(val)}
                className="flex items-center gap-2"
              >
                <RadioGroup.Label className="sr-only">Subscriber Growth Feed Type</RadioGroup.Label>
                <RadioGroup.Option value={GrowthDataFeedType.OVERALL} defaultChecked>
                  {({ active, checked }) => (
                    <div className={classes.growthFeed(active, checked)}>
                      <div className="w-1.5 h-1.5 rounded-full bg-green-800 relative mr-2">
                        <span className="absolute block w-1.5 h-0.75 bg-red-400 rounded-t-md transform origin-center-bottom -rotate-135 " />
                      </div>
                      <span className="text-xs text-gray-900 font-medium"> Overall</span>
                    </div>
                  )}
                </RadioGroup.Option>
                <RadioGroup.Option value={GrowthDataFeedType.SUBSCRIBES}>
                  {({ active, checked }) => (
                    <div className={classes.growthFeed(active, checked)}>
                      <div className="w-1.5 h-1.5 rounded-full bg-accent-600 mr-2" />
                      <span className="text-xs text-gray-900 font-medium"> Subscribed</span>
                    </div>
                  )}
                </RadioGroup.Option>
                <RadioGroup.Option value={GrowthDataFeedType.UNSUBSCRIBES}>
                  {({ active, checked }) => (
                    <div className={classes.growthFeed(active, checked)}>
                      <div className="w-1.5 h-1.5 rounded-full bg-red-400 mr-2" />
                      <span className="text-xs text-gray-900 font-medium"> Unsubscribed</span>
                    </div>
                  )}
                </RadioGroup.Option>
              </RadioGroup>
            </div>
            <div className="flex items-end mt-1.5">
              {analytics?.chartMetric?.change?.direction === 'up' && (
                <Glyph.CaretUp className="text-accent-400 w-2.5" />
              )}
              {analytics?.chartMetric?.change?.direction === 'down' && (
                <Glyph.CaretDown className="text-red-500 w-2.5" />
              )}
              {analytics?.chartMetric?.change?.direction === 'up' && (
                <span className="ml-1.5 -mb-2 text-accent-600 text-4xl font-normal">
                  {analytics?.chartMetric?.currentValue}
                </span>
              )}
              {analytics?.chartMetric?.change?.direction === 'down' && (
                <span className="ml-1.5 -mb-2 text-red-500 text-4xl font-normal">
                  {analytics?.chartMetric?.currentValue}
                </span>
              )}
            </div>
            <div className="mt-6">
              {analytics?.chartMetric && (
                <ResponsiveContainer width="100%" height={185}>
                  {selectedGrowthFeed === GrowthDataFeedType.OVERALL ? (
                    <AreaChart data={analytics?.chartMetric.chartData}>
                      <CartesianGrid stroke="#E3E8E8" strokeDasharray="2 0" vertical={false} />
                      <XAxis
                        tick={{ strokeWidth: 0.5, stroke: '#657676' }}
                        dataKey="name"
                        style={{ fontSize: 12 }}
                      />
                      <YAxis
                        tick={{ strokeWidth: 0.5, stroke: '#657676' }}
                        domain={['auto', 'auto']}
                        style={{ fontSize: 12 }}
                        allowDecimals={false}
                        tickLine={false}
                        axisLine={false}
                      />
                      <ChartTooltip content={<GrowthChartTooltip feed={selectedGrowthFeed} />} />
                      <defs>
                        <linearGradient id="fillColor" x1="0" y1="0" x2="0" y2="1">
                          <stop offset={'0%'} stopColor="#065F46" stopOpacity={0.6} />
                          <stop
                            offset={getGrowthGradientOffset(
                              analytics?.chartMetric.chartData,
                              GrowthDataFeedType.OVERALL,
                            )}
                            stopColor="#065F46"
                            stopOpacity={0.2}
                          />
                          <stop
                            offset={getGrowthGradientOffset(
                              analytics?.chartMetric.chartData,
                              GrowthDataFeedType.OVERALL,
                            )}
                            stopColor="#F87171"
                            stopOpacity={0.2}
                          />
                          <stop offset={'100%'} stopColor="#F87171" stopOpacity={0.6} />
                        </linearGradient>
                        <linearGradient id="strokeColor" x1="0" y1="0" x2="0" y2="1">
                          <stop
                            offset={getGrowthGradientOffset(
                              analytics?.chartMetric.chartData,
                              GrowthDataFeedType.OVERALL,
                            )}
                            stopColor="#065F46"
                          />
                          <stop
                            offset={getGrowthGradientOffset(
                              analytics?.chartMetric.chartData,
                              GrowthDataFeedType.OVERALL,
                            )}
                            stopColor="#F87171"
                          />
                        </linearGradient>
                      </defs>
                      <Area
                        type="linear"
                        dataKey="overall"
                        strokeWidth={2}
                        stroke="url(#strokeColor)"
                        fill="url(#fillColor)"
                        activeDot={{ r: 4 }}
                      />
                    </AreaChart>
                  ) : (
                    <AreaChart data={analytics?.chartMetric.chartData}>
                      <CartesianGrid stroke="#E3E8E8" strokeDasharray="2 0" vertical={false} />
                      <XAxis
                        tick={{ strokeWidth: 0.5, stroke: '#657676' }}
                        dataKey="name"
                        style={{ fontSize: 12 }}
                      />
                      <YAxis
                        tick={{ strokeWidth: 0.5, stroke: '#657676' }}
                        style={{ fontSize: 12 }}
                        allowDecimals={false}
                        tickLine={false}
                        axisLine={false}
                      />
                      <ChartTooltip content={<GrowthChartTooltip feed={selectedGrowthFeed} />} />
                      <defs>
                        <linearGradient id="fillColor" x1="0" y1="0" x2="0" y2="1">
                          <stop offset={'0%'} stopColor="#065F46" stopOpacity={0.6} />
                          <stop
                            offset={getGrowthGradientOffset(
                              analytics?.chartMetric.chartData,
                              GrowthDataFeedType.SUBSCRIBES,
                            )}
                            stopColor="#065F46"
                            stopOpacity={0.2}
                          />
                          <stop offset={'100%'} stopColor="#065F46" stopOpacity={0.6} />
                        </linearGradient>
                        <linearGradient id="fillColorUnsubscribes" x1="0" y1="0" x2="0" y2="1">
                          <stop offset={'0%'} stopColor="#F87171" stopOpacity={0.6} />
                          <stop
                            offset={getGrowthGradientOffset(
                              analytics?.chartMetric.chartData,
                              GrowthDataFeedType.UNSUBSCRIBES,
                            )}
                            stopColor="#F87171"
                            stopOpacity={0.2}
                          />
                          <stop offset="100%" stopColor="#F87171" stopOpacity={0.2} />
                        </linearGradient>
                      </defs>
                      {selectedGrowthFeed === GrowthDataFeedType.SUBSCRIBES && (
                        <Area
                          type="linear"
                          dataKey="subscribes"
                          strokeWidth={2}
                          stroke="#065F46"
                          fill="url(#fillColor)"
                          activeDot={{ r: 4 }}
                        />
                      )}
                      {selectedGrowthFeed === GrowthDataFeedType.UNSUBSCRIBES && (
                        <Area
                          type="linear"
                          dataKey="unsubscribes"
                          strokeWidth={2}
                          stroke="#F87171"
                          fill="url(#fillColorUnsubscribes)"
                          activeDot={{ r: 4 }}
                        />
                      )}
                    </AreaChart>
                  )}
                </ResponsiveContainer>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  )
}
