import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
// This import is done like this to avoid compilation issues in Next 122
// @ts-ignore
import { yupResolver } from '@hookform/resolvers/yup'
import classNames from 'classnames'
import slugFunc from 'slug'

import { ButtonDefault, ButtonSecondary } from '@tellimer/ui/Button'
import { Input, InputGroup } from '@tellimer/ui/Input'
import { LoadingBar } from '@tellimer/ui/LoadingBar'
import { NotificationManager, NotificationLayout } from '@tellimer/ui/Notification'

import { StepperType, OnboardingStepTypes } from 'types/onboarding.types'

import { publicationSelector } from 'redux/publication/selector'
import { setPublication } from 'redux/publication/slice'
import { completeOnboardingStep } from 'redux/onboardingProgress/slice'
import { updatePublicationDetails } from 'redux/publication/actions/updatePublicationDetails'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'

type PubDetailsFormValues = {
  name: string
  about: string
  domainName: string
}

type Props = {
  step: OnboardingStepTypes
  skip: CallableFunction
  variant: StepperType
}

export const PublicationDetails = ({ step, variant, skip }: Props) => {
  const dispatch = useAppDispatch()
  const { publication } = useAppSelector(publicationSelector)
  const [isSaving, setIsSaving] = useState<boolean>(false)

  const validationSchema = yup.object({
    name: yup.string().required('This field is required.'),
    about: yup.string(),
    domainName: yup
      .string()
      .required('This field is required.')
      .matches(
        /^[A-Za-z0-9](?:[A-Za-z0-9\-]{0,61}[A-Za-z0-9])?$/,
        'Please enter a valid sub domain',
      )
      .min(5, 'Your subdomain must be at least 5 characters long'),
  })

  const {
    handleSubmit,
    register,
    formState: { errors, isDirty },
    setError,
    setValue,
  } = useForm<PubDetailsFormValues>({
    resolver: yupResolver(validationSchema),
    reValidateMode: 'onChange',
  })

  useEffect(() => {
    if (!publication) return
    setValue('name', publication?.name ?? '')
    setValue('about', publication?.about ?? '')
    setValue('domainName', publication?.domain?.name ?? '')
  }, [publication])

  const onChangePubName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.value
    const domainName = slugFunc(name, '-')
    setValue('name', name, { shouldDirty: true })
    setValue('domainName', domainName)
  }

  const onSubmit = async (data: PubDetailsFormValues) => {
    try {
      setIsSaving(true)

      const { name, about, domainName } = data

      // We optimistically store the data locally and open the next step
      dispatch(
        setPublication({
          name,
          about,
          domain: {
            name: domainName,
            isSubdomain: true,
          },
        }),
      )

      // Now we update for real on the backend
      await dispatch(updatePublicationDetails({ name, about, domainName })).unwrap()
      //FIXME each step should have validation criteria for completion applied as per the onboarding object
      // e.g. OnboardingPublicationStep defines what fields make a step complete
      dispatch(completeOnboardingStep(step))
      setIsSaving(false)
    } catch (err: any) {
      console.error(err)
      setIsSaving(false)

      let notificationTitle = 'An error occurred while setting your publication details'
      if (err.code && err.code === 'SUBDOMAIN_NOT_AVAILABLE') {
        notificationTitle = 'That domain is already taken'
        setError('domainName', { message: notificationTitle })
        return
      }
      if (err.code && err.code === 'SUBDOMAIN_NOT_VALID') {
        notificationTitle = `${data.domainName} ${err.message}`
        setError('domainName', { message: notificationTitle })
        return
      }

      NotificationManager.create({
        type: 'error',
        layout: NotificationLayout.CONDENSED,
        title: notificationTitle,
      })
    }
  }

  const classes = {
    stepperDescription: classNames({
      'text-gray-900': true,
      'text-base': variant === StepperType.PRIMARY,
      'text-sm': variant === StepperType.SECONDARY,
    }),
    btnGroup: classNames({
      'mt-6 flex gap-4': true,
      'flex-col items-stretch md:items-center md:flex-row': variant === StepperType.PRIMARY,
      'flex-col': variant === StepperType.SECONDARY,
    }),
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <p className={classes.stepperDescription}>
        Your publication name, description and web address goes here.
      </p>
      <InputGroup
        className="mt-6"
        label="Publication name"
        hasError={!!errors.name}
        errorMsg={errors.name?.message}
      >
        <Input
          {...register('name')}
          id="name"
          type="text"
          name="name"
          hasError={!!errors.name}
          onChange={onChangePubName}
        />
      </InputGroup>
      <InputGroup
        className="mt-6"
        label="About your publication"
        bottomHint="Write a few sentences about your publication. This appears on your creator page."
        hasError={!!errors.about}
        errorMsg={errors.about?.message}
      >
        <Input
          {...register('about')}
          id="about"
          type="textarea"
          name="about"
          rows={5}
          hasError={!!errors.about}
        />
      </InputGroup>
      <InputGroup
        className="mt-6"
        label="Web address"
        hasError={!!errors.domainName}
        errorMsg={errors.domainName?.message}
      >
        <Input
          {...register('domainName')}
          id="domainName"
          type="text"
          name="domainName"
          suffix=".scriber.to"
          hasError={!!errors.domainName}
        />
      </InputGroup>
      <div className={classes.btnGroup}>
        <ButtonSecondary
          className="h-9.5"
          type="submit"
          disabled={isSaving || !isDirty}
          data-testid="publication-step-save-btn"
        >
          {isSaving && <LoadingBar size="sm" className="mr-1" />} Save and continue
        </ButtonSecondary>
        <ButtonDefault onClick={() => skip()} data-testid="publication-step-skip-step-btn">
          Skip this step
        </ButtonDefault>
      </div>
    </form>
  )
}

PublicationDetails.displayName = 'PublicationDetails'
