import React, { useEffect } from 'react'

import { LoadingBar } from '@tellimer/ui/LoadingBar'

import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'

import { StepperType } from 'types/onboarding.types'

import { selectCreator } from 'redux/auth/selector'
import { getOnboarding } from 'redux/onboarding/selector'
import { selectOnboardingProgress } from 'redux/onboardingProgress/selector'

import { OnboardingStepper } from './OnboardingStepper'
import { fetchOnboardingProgress } from 'redux/onboardingProgress/fetchOnboardingProgress'
import { fetchOnboarding } from 'redux/onboarding/fetchOnboarding'
import { initOnboardingSubscription } from 'redux/onboardingProgress/initOnboardingSubscription'
import { setFatalError } from 'redux/app/slice'

const Onboarding = () => {
  const dispatch = useAppDispatch()
  const creator = useAppSelector(selectCreator)
  const onboardingInfo = useAppSelector(getOnboarding)
  const progress = useAppSelector(selectOnboardingProgress)

  useEffect(() => {
    ;(async () => {
      try {
        await Promise.all([
          dispatch(fetchOnboardingProgress()).unwrap(),
          dispatch(fetchOnboarding()).unwrap(),
          dispatch(initOnboardingSubscription()).unwrap(),
        ])
      } catch (err) {
        console.error('Something failed while loading onboarding details', err)
        dispatch(setFatalError())
      }
    })()
  }, [dispatch, creator?.id])

  if (!creator || !onboardingInfo || !progress) {
    return (
      <div className="absolute left-0 top-0 h-screen w-full bg-gray-50 z-10000">
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <LoadingBar size="lg" />
        </div>
      </div>
    )
  }

  return (
    <div className="h-full px-6 py-6 md:py-16 mx-auto my-0 max-w-4xl">
      <h1 className="md:ml-6 font-brick text-3xl md:text-5xl text-gray-900">
        Welcome to your Dashboard{creator?.fullName ? `, ${creator?.fullName}` : ''}.
      </h1>
      {/* <Concierge type="responsive" /> */}
      <OnboardingStepper className="mt-4" variant={StepperType.PRIMARY} />
    </div>
  )
}

Onboarding.displayName = 'OnboardingPage'
export default Onboarding
