import React from 'react'
import { LoadingBar } from '@tellimer/ui'

import {
  selectIsSettingUpStripe,
  selectPaymentsAreLoading,
  selectPaymentsSettings,
} from 'redux/payments/selector'
import { useAppSelector } from 'hooks/reduxHooks'
import { OnboardingStepTypes, StepperType } from 'types/onboarding.types'

import { SubscriptionTierList } from 'components/payments/SubscriptionTierList'
import { StripeSetup } from 'components/payments/StripeSetup'

export const GettingPaid = ({
  skip,
  step,
  variant,
}: {
  step: OnboardingStepTypes
  skip: () => void
  variant: StepperType
}) => {
  const settings = useAppSelector(selectPaymentsSettings)
  const isLoading = useAppSelector(selectPaymentsAreLoading)
  const isSettingUpStripe = useAppSelector(selectIsSettingUpStripe)

  if (isLoading || isSettingUpStripe) {
    return (
      <div className="w-full my-20 flex justify-center items-center">
        <LoadingBar size="lg" />
      </div>
    )
  }

  return !settings?.isConnected ? (
    <StripeSetup skip={skip} variant={variant} />
  ) : (
    <SubscriptionTierList step={step} skip={skip} variant={variant} />
  )
}

GettingPaid.displayName = 'GettingPaid'
