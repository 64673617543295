import React from 'react'
import Head from 'next/head'

import { useAppSelector } from 'hooks/reduxHooks'
import { selectHasPublishedPosts } from 'redux/post/selector'

import Layout from 'components/app/Layout'
import Onboarding from 'components/onboarding'
import Dashboard from 'components/dashboard'
import { NextPageWithLayout } from 'types/common.types'
import { APP_NAME } from 'helper/constants'
import { selectCreatorHasCompletedOnboarding } from 'redux/auth/selector'

const Home: NextPageWithLayout = () => {
  const hasPublishedPosts = useAppSelector(selectHasPublishedPosts)
  const hasCompletedOnboarding = useAppSelector(selectCreatorHasCompletedOnboarding)

  if (!hasPublishedPosts && !hasCompletedOnboarding) {
    return (
      <>
        <Head>
          <title>{APP_NAME} | Onboarding</title>
        </Head>
        <Onboarding />
      </>
    )
  }

  return (
    <>
      <Head>
        <title>{APP_NAME} | Dashboard</title>
      </Head>
      <Dashboard />
    </>
  )
}

Home.getLayout = page => <Layout>{page}</Layout>

export default Home
